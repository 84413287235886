<script setup>
import { ref, onBeforeMount, onMounted, defineProps, watch } from "vue";
import { i18n } from '../main'; // Import the i18n instance

import axios from 'axios';
const props = defineProps(['locale', 'isDarkMode']);
const API_BASE_URL = ref(process.env.VUE_APP_API_BASE_URL);
const news = ref([]);
const relatednews = ref([]);
// const filteredRelatedNews = ref([]);
import { useRoute, useRouter  } from 'vue-router';
const route = useRoute();
const router = useRouter(); 
const categorie = ref(route.params.categorie);
const title = ref(route.params.slug);
const categories = ref([]);
const paragraphcontents = ref([]);
const direction = ref("rtl");
// ads posts devider
const sectionsbeforeads = ref([]); 
const sectionsafterads = ref([]); 
const isLoading = ref(false); // Loading state
//keywords 
const keywordsMap = ref(); 
onMounted(async () => {
  await fetchNews();
  await fetchkeywords(); 
  processNewsContent(); // Process content after data is fetched
  await fetchRelatedNews();
  fetchCategories();
  setBackgroundImages();
});
function replaceKeywordsWithLinks(text) {
  Object.keys(keywordsMap.value).forEach(keyword => {
    const url = keywordsMap.value[keyword];
    const currentUrl = route.fullPath; // or this.$route.path if you prefer
    if (url !== currentUrl){
       // Create a regex for the keyword
    const regex = new RegExp(`(${keyword})`, 'i'); // Case-insensitive matching
    text = text.replace(regex, `<strong><a href="${url}"  class="internal_link">$1</a></strong>`); // Use $1 to refer to the matched keyword
    }

  });
  return text;
}
function processNewsContent() {
  // This function will process the news content or sections and replace keywords
  news.value.introduction = replaceKeywordsWithLinks(news.value.introduction) ;
  news.value.sections = news.value.sections.map(section => {
    section.section_content = replaceKeywordsWithLinks(section.section_content);
    return section;
  });
  
}
async function fetchkeywords(){
  try{
    await axios.get(`${API_BASE_URL.value}/keywords`)
  .then(response => {
      keywordsMap.value = response.data;
    })
  }catch (error) {
    console.error(error);
    throw error; // Rethrow the error to handle it later
  }
  
}

function fetchCategories(){
  axios.get(`${API_BASE_URL.value}/categories`)
    .then(response => {
      categories.value = response.data;
    })
    .catch(error => {
      console.log(error);
    });
}

function setBackgroundImages() {
  const parents = document.getElementsByClassName("imagecontainer");
  for (let index = 0; index < parents.length; index++) {
    const relatednewsimagecontainer = document.getElementById(index);
    const image = relatednews.value[index].new.image;
    relatednewsimagecontainer.style.backgroundImage = `url(${image})`;
  }
}

const exist = ref(false);
onBeforeMount(() => {
  if (window.innerWidth > 768) {
    exist.value = true;
  }
});
// Watch for route parameter changes
watch(
  () => route.params, // Watch the route path
  async(newParams) => {
  categorie.value = newParams.categorie;
  title.value = newParams.slug;
  await fetchNews();
  await fetchRelatedNews();
  setBackgroundImages();
  scrollToTop(); // Scroll to the top when parameters change
  }
);

watch(() => props.locale, async (newData) => {
  props.locale === newData;
  await fetchNews();
  await fetchRelatedNews();
  fetchCategories();
  i18n.global.locale = props.locale;
});

async function displayTruncatedParagraph(data) {
  const truncatedParagraph1 = truncateParagraph(data, 10);
  paragraphcontents.value.push(truncatedParagraph1);
}
// Function to scroll to the top of the page
function scrollToTop() {
  window.scrollTo(0, 0); // Scroll to the top
}
function truncateParagraph(paragraph, wordCount) {
  const words = paragraph.split(" ");
  const truncatedWords = words.slice(0, wordCount);
  return truncatedWords.join(" ") + "...";
}
function setMetaDescription(content){
  let metaDescription = document.querySelector('meta[name="description"]')
  if (metaDescription) {
    metaDescription.content = content // Set the content of the meta description
  } else {
    // Create a new meta tag if it doesn't exist
    metaDescription = document.createElement('meta')
    metaDescription.name = 'description'
    metaDescription.content = content
    document.head.appendChild(metaDescription)
  }
}
async function fetchRelatedNews() {
  try {
    paragraphcontents.value = []; // Clear the previous content

    const category = news.value.category.name;
    const api = `${API_BASE_URL.value}/arabic_news/${category}`;
    
    const response = await axios.get(api);
    const filteredNews = response.data.results.filter(item => item.new.general_title !== title.value)
    relatednews.value = filteredNews
    .sort(() => 0.5 - Math.random()) // Shuffle the array randomly
    .slice(0, 6); // Limit to 6 items

    for (let i = 0; i < relatednews.value.length; i++) {
      await displayTruncatedParagraph(relatednews.value[i].introduction);
    }
  } catch (error) {
    console.error(error);
    throw error; // Rethrow the error to handle it later
  }
}

async function fetchNews() {
  isLoading.value = true; // Set loading to true before fetching data
  try {
    const response = await axios.get(`${API_BASE_URL.value}/arabic_news/${categorie.value}/${title.value}`);
    news.value = response.data;
    //add meta description to html and title 
    document.title = news.value.meta_title
    setMetaDescription(news.value.meta_description) // Set the meta description
    if(news.value.sections.length <=4){
      sectionsbeforeads.value = news.value.sections; 
      sectionsafterads.value = []; 
    }else{
      sectionsbeforeads.value = news.value.sections.slice(0, 4); 
      sectionsafterads.value = news.value.sections.slice(4); 
    }
    await switchLanguage(news.value.language);
  } catch (error) {
    console.error(error);
    throw error; // Rethrow the error to handle it later
  }finally{
    isLoading.value = false; 
  }
}

async function switchLanguage(locale) {
  props.locale === locale;
  i18n.global.locale = props.locale;
  direction.value = (locale === "ar") ? "rtl" : "ltr";
  document.querySelector("html").setAttribute('dir', direction.value);
}

const navigateToNewPage = (param01, param02) => {
  router.push({ name: 'new', params: { categorie: param02 , slug : param01 } })
};
</script>

<template>
  <div class="news_categories">
    <div class="container-fluid px-3">
      <h1 class="mt-3">{{ $t('latest news') }}</h1>
      <div class="categorie mt-4 d-flex flex-wrap">
        <div v-for="categorie in categories" :key="categorie.category_id" class="mb-1 d-inline">
          <router-link :to="{ name: 'categorie', params: { categorie: categorie.category_id } }" class="text-decoration-none me-2 text-center px-3">{{ categorie.name }}</router-link>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isLoading" class="loading-container text-center my-4"> 
  <div class="spinner"></div>
  <p>{{ $t('loading') }}</p>
</div>
  <div class="row mx-4">
    <div class="col-md-10">
      <div class="my-4">
        <h2 id="heading">{{ news.title }}</h2>
      </div>
      <div class="my-4">
        <p v-html="news.introduction"></p>
      </div>
      <div v-if="news.image" class="my-4 w-75 mx-auto ">
        <img :src="news.image" :alt="news.image_alt" class="w-100 rounded shadow-sm " style="max-height: 300px; object-fit: fill;" >
      </div>
      <!-- ads section one  -->
      <div class="my-4">
        <!-- the ad will be here  -->
      </div>
      <!-- end ads -->
      <div class="my-4" v-for="section in sectionsbeforeads" :key="section.section_title">
        <h3>{{ section.section_title }}</h3>
        <p v-html="section.section_content"></p>
        <div v-if="section.section_image" class="my-4 w-75 mx-auto">
        <img :src="section.section_image" :alt="section.section_image_alt" class="w-100 rounded shadow-sm" style="max-height: 300px; object-fit: fill;" >
      </div>
      </div>
      <!-- ads section two -->
      <div class="my-4">
        <!-- the ad will be here  -->
      </div>
      <!-- end ads  -->
      <div class="my-4" v-for="section in sectionsafterads" :key="section.section_title">
        <h3>{{ section.section_title }}</h3>
        <p v-html="section.section_content"></p>
        <div v-if="section.section_image" class="my-4 w-75 mx-auto">
        <img :src="section.section_image" :alt="section.section_image_alt" class="w-100 rounded shadow-sm" style="max-height: 300px; object-fit: fill;" >
      </div>
      </div>
      <h2 class="mb-4" id="relatedposts">{{ $t('related posts') }}</h2>
      <div class="relatednewscontainer">
        <div class="row mb-4">
          <div class="p-2 col-lg-4 col-md-6" v-for="(item, index) in relatednews" :key="index">
            <a @click="navigateToNewPage(item.new.slug, item.new.category.general_name)" style="cursor: pointer;" class="text-decoration-none">
              <div class="card border-0 my-1 position-relative imagecontainer" style="height:170px; background-size: cover; background-position: center; background-repeat: no-repeat; overflow: hidden;" :id="index">
                <div class="overlay z-1"></div>
                <div class="card-img-overlay z-2" style="color: white;">
                  <h5 class="card-title">{{ item.title }}</h5>
                  <p :id="item.introduction" class="card-text">{{paragraphcontents[index] }}</p>
                </div>
              </div>  
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
/* Add your styles here */
</style>
